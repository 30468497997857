import React from "react";
import { FormStep } from "@helpers/ApplicationFormHooks";
import { ApplicationStepPage } from "@components/ApplicationStepPage/ApplicationStepPage";
import { INSTITUTION_FORM_STEPS } from "./_useInstitutionFormState";
import SEO from "@components/PageLayout/SEO";
import { PageLayoutMetaData } from "@components/PageLayout/_PageLayoutMetaData";

const SIDEBAR_STEPS = [
	INSTITUTION_FORM_STEPS.CONTACT,
	INSTITUTION_FORM_STEPS.INSTITUTION,
	INSTITUTION_FORM_STEPS.USE,
	INSTITUTION_FORM_STEPS.SUMMARY,
];

type InstitutionStepPageProps = {
	currentStepIndex: FormStep;
	isLoading?: boolean;
	onButtonClick: () => void;
	onBackClick?: () => void;
	children: React.ReactNode;
};

export const InstitutionStepPage = (props: InstitutionStepPageProps) => (
	<>
		<SEO title={PageLayoutMetaData.institution.title[props.currentStepIndex]} />
		<ApplicationStepPage
			pageTitle="Genemod's Institution Program"
			steps={SIDEBAR_STEPS}
			{...props}
		/>
	</>
);
