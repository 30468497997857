import React, { useEffect, useState } from "react";
import {
	useInstitutionFormState,
	INSTITUTION_FORM_STEPS,
} from "../_useInstitutionFormState";
import { InstitutionStepPage } from "../_InstitutionStepPage";
import {
	useSummaryCommon,
	SectionHeader,
	FieldLine,
} from "@components/ApplicationStepPage/common/Summary/useSummaryCommon";
import { useDropzone } from "react-dropzone";
import { Typography } from "@components/index";
import * as styles from "./index.module.scss";

const INSTITUTION_APP_ROUTE = `https://api.hsforms.com/submissions/v3/integration/submit/19551518/85384adc-4bfc-4020-95b7-e36821e802fd`;

const InstitutionSummary: React.FunctionComponent = () => {
	const formData = useInstitutionFormState(
		INSTITUTION_FORM_STEPS.SUMMARY.formStep
	);
	const { formState, getInputProps, navigateToStep } = formData;
	const [fileBlob, setFileBlob] = useState<any>(null);

	const { onSubmit, submitStatus, getContactSection, getCertification } =
		useSummaryCommon({
			formData: {
				...formData,
				formState: {
					...formState,
					"0-2/institution_verification_documents": fileBlob,
				},
			},
			hubspotUrl: INSTITUTION_APP_ROUTE,
			onSuccess: () =>
				navigateToStep(INSTITUTION_FORM_STEPS.CONFIRMATION.formStep),
		});

	let websiteHref = formState["0-2/institution_website_url"];
	if (!formState["0-2/institution_website_url"]?.includes("http")) {
		websiteHref = "//" + websiteHref;
	}

	return (
		<InstitutionStepPage
			currentStepIndex={INSTITUTION_FORM_STEPS.SUMMARY.formStep}
			onButtonClick={onSubmit}
			onBackClick={() =>
				navigateToStep(INSTITUTION_FORM_STEPS.USE.formStep)
			}
			isLoading={submitStatus === "SUBMITTING"}
		>
			<div>
				{getContactSection(INSTITUTION_FORM_STEPS.CONTACT)}
				<SectionHeader stepData={INSTITUTION_FORM_STEPS.INSTITUTION} />
				<FieldLine {...getInputProps("0-2/institution_name")} />
				<FieldLine
					{...getInputProps("0-2/institution_website_url")}
					value={
						<a href={websiteHref} target="_blank">
							{formState["0-2/institution_website_url"]}
						</a>
					}
				/>
				<FieldLine {...getInputProps("0-2/institution_location")} />
				<FieldLine {...getInputProps("team_size_range")} />
				<Typography
					variant="CAPTION"
					color="text-dark"
					className={styles.label}
				>
					Verification documents (optional)
				</Typography>
				<DragDropFile setFileBlob={setFileBlob} />
				{getCertification()}
			</div>
		</InstitutionStepPage>
	);
};

const MAX_SIZE = 4 * (1024 * 1024); // 4MB

const DragDropFile = ({ setFileBlob }: { setFileBlob: (f: any) => void }) => {
	const [fileData, setFileData] = useState<any>(null);
	const [error, setError] = useState<any>(null);

	const errorMessage = {
		INVALID: "File type not supported.",
		OVERSIZE: "File size exceeds 4MB.",
		SYSTEM: "Try again. \n If the issue continues, contact us at support@genemod.net",
	};

	const onDrop = async (
		acceptedFiles: any,
		fileRejections: any,
		event: any
	) => {
		if (
			!acceptedFiles[0] ||
			(fileRejections[0] &&
				event.dataTransfer.files[0] === fileRejections[0].file)
		) {
			setError({
				fileName: fileRejections[0].file.name,
				message:
					fileRejections[0].errors[0].code === "file-invalid-type"
						? errorMessage.INVALID
						: errorMessage.OVERSIZE,
			});
		} else {
			const [file] = acceptedFiles;
			if (file) {
				try {
					setFileData(file);
					const blob = await URL.createObjectURL(file);
					setFileBlob(blob);
				} catch (e) {
					setError({
						fileName: file.name,
						message: errorMessage.SYSTEM,
					});
				}
			}
		}
	};

	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		onDrop,
		noClick: true,
		noKeyboard: true,
		multiple: false,
		maxSize: MAX_SIZE,
	});

	return (
		<>
			<div
				className={styles.uploadWrapper}
				style={{
					backgroundColor: isDragActive
						? "var(--lighter)"
						: "transparent",
				}}
			>
				<div
					{...getRootProps({
						className: styles.dropzone,
					})}
				>
					<input {...getInputProps()} />
					{(() => {
						if (fileData) {
							return (
								<Typography variant="CAPTION">
									{fileData.name}
								</Typography>
							);
						}

						return (
							<>
								<Typography
									style={{ marginRight: 6 }}
									variant="CAPTION"
									color="cloudy-blue"
								>
									Drag and drop your file here or
								</Typography>
								<a onClick={open}>upload</a>
							</>
						);
					})()}
				</div>
			</div>
			{error && (
				<Typography color="medium-pink">*{error?.message}</Typography>
			)}
		</>
	);
};

export default InstitutionSummary;
