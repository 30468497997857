import React from "react";
import {
	FormStep,
	FormReleaseVersion,
	useApplicationFormState,
} from "@helpers/ApplicationFormHooks";
import {
	ContactDetailsIcon,
	TeamDetailsIcon,
	UseForGenemodIcon,
	SummaryIcon,
} from "@components/ApplicationStepPage/ApplicationStepIcons";
import {
	USE_PAGE_LABELS,
	USE_PAGE_INITIAL_VALUES,
} from "@components/ApplicationStepPage/common/Use/useApplicationUsePageFields";
import {
	CONTACT_PAGE_LABELS,
	CONTACT_PAGE_INITIAL_VALUES,
} from "@components/ApplicationStepPage/common/Contact/useApplicationContactFields";

export const INSTITUTION_FORM_STEPS = {
	START: {
		formStep: 0 as FormStep,
		name: "N/A",
		getIcon: (_complete: boolean) => <></>,
		url: "/application/institution",
	},
	CONTACT: {
		formStep: 1 as FormStep,
		name: "Contact details",
		getIcon: (complete: boolean) => (
			<ContactDetailsIcon complete={complete} />
		),
		url: "/application/institution/contact",
	},
	INSTITUTION: {
		formStep: 2 as FormStep,
		name: "Institution details",
		getIcon: (complete: boolean) => <TeamDetailsIcon complete={complete} />,
		url: "/application/institution/details",
	},
	USE: {
		formStep: 3 as FormStep,
		name: "Use for Genemod",
		getIcon: (complete: boolean) => (
			<UseForGenemodIcon complete={complete} />
		),
		url: "/application/institution/use",
	},
	SUMMARY: {
		formStep: 4 as FormStep,
		name: "Summary",
		getIcon: (complete: boolean) => <SummaryIcon complete={complete} />,
		url: "/application/institution/summary",
	},
	CONFIRMATION: {
		formStep: 5 as FormStep,
		getIcon: (_complete: boolean) => <></>,
		name: "N/A",
		url: "/application/institution/confirmation",
	},
};

const INITIAL_INSTITUTION_FORM_STATE = {
	latestCompletedStep: INSTITUTION_FORM_STEPS.START.formStep,
	formReleaseVersion: 0 as FormReleaseVersion,
	// Contact page
	...CONTACT_PAGE_INITIAL_VALUES,
	// Details page
	"0-2/institution_name": "",
	"0-2/institution_website_url": "",
	"0-2/institution_location": "",
	team_size_range: "",
	// Use page
	...USE_PAGE_INITIAL_VALUES,
};

const INSTITUTION_FIELD_LABELS = {
	latestCompletedStep: "N/A",
	formReleaseVersion: "N/A",
	// Contact page
	...CONTACT_PAGE_LABELS,
	// Details page
	"0-2/institution_name": "Institution name",
	"0-2/institution_website_url": "Institution website",
	"0-2/institution_location": "Institution location",
	team_size_range: "Team size",
	// Use page
	...USE_PAGE_LABELS,
};

/**
 * Cross page institution form state
 */
export const useInstitutionFormState = (currentStep: FormStep) =>
	useApplicationFormState({
		formName: "institutionForm",
		currentStep,
		initialState: INITIAL_INSTITUTION_FORM_STATE,
		labels: INSTITUTION_FIELD_LABELS,
		stepsData: INSTITUTION_FORM_STEPS,
	});
